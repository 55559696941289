/* Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  color: #000; /* Okların rengi */
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #5e5a5a; /* Hover durumunda okların rengi */
}

/* Swiper pagination bullets */
.swiper-pagination-bullet {
  background: #5e5a5a; /* Noktaların rengi */
  opacity: 1; /* Noktaların tamamen görünür olması için */
}

.swiper-pagination-bullet-active {
  background: #000; /* Aktif noktaların rengi */
}
