@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  overflow-x: hidden;
}

.mySwiper img {
  height: 500px;
  width: 100%;
  object-fit: cover;
}
